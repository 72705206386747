import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Account } from '@Types/account/Account';
import { useAccount } from 'frontastic';
import SecureCheckout from '../informationRequired';
import LoginModalContent from './loginModalContent';

export interface LoginProps {
  loginModal?: any;
  closeModal?: any;
  loginCallBack?: () => void;
  closeAccountFlyout?: React.Dispatch<React.SetStateAction<boolean>>;
  continueAsGuestEligible?: boolean;
  isRememberMeSessionExpired?: boolean;
  triggerRetrieveUsername?: boolean;
  existingUser?: Account;
  isProfileUpdate?: boolean;
  updateAccountData?: any;
  isPasswordChange?: boolean;
  passwordChangeData?: any;
  changeAccountPassword?: any;
}

function Login({
  loginModal,
  closeModal,
  loginCallBack = () => {},
  closeAccountFlyout,
  continueAsGuestEligible,
  isRememberMeSessionExpired,
  triggerRetrieveUsername,
  existingUser,
  isProfileUpdate,
  updateAccountData,
  isPasswordChange,
  passwordChangeData,
  changeAccountPassword,
}: LoginProps) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [showSecureCheckout, setShowSecureCheckout] = useState(false);
  const { account } = useAccount();
  useEffect(() => {
    document.documentElement.style.overflow = '';
    return () => {
      document.documentElement.style.overflow = '';
    };
  }, []);

  return (
    <>
      <Transition appear show={loginModal} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-black fixed inset-0 bg-opacity-25" />
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <section className="absolute inset-0" onClick={closeModal}>
                  <section
                    className="absolute top-1/2 left-1/2 z-[100] h-[620px] max-h-[700px] w-[350px] -translate-x-1/2 -translate-y-1/2 overflow-y-auto rounded bg-white px-[30px] pt-6 pb-4 dark:bg-primary-200 md:mt-9 md:w-[420px] lg:mt-3 lg:w-[460px]"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <section className="absolute top-3 right-3"></section>
                    <section className="text-left">
                      <LoginModalContent
                        closeModal={() => closeModal(false)}
                        closeAccountFlyout={closeAccountFlyout}
                        loginCallBack={loginCallBack}
                        continueAsGuestEligible={continueAsGuestEligible}
                        closeFlyout={() => {}}
                        sectionRef={() => {}}
                        inputRef={() => {}}
                        handleInputFocus={() => {}}
                        handleInputBlur={() => {}}
                        handleForgotCredentials={() => {}}
                        isRememberMeSessionExpired={isRememberMeSessionExpired}
                        triggerRetrieveUsername={triggerRetrieveUsername}
                        account={existingUser ? existingUser : account}
                        isProfileUpdate={isProfileUpdate}
                        updateAccountData={updateAccountData}
                        isPasswordChange={isPasswordChange}
                        passwordChangeData={passwordChangeData}
                        changeAccountPassword={changeAccountPassword}
                      ></LoginModalContent>
                    </section>
                  </section>
                </section>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {showSecureCheckout && (
        <SecureCheckout closeModal={() => setShowSecureCheckout(false)} informationRequired={showSecureCheckout} />
      )}
    </>
  );
}

export default Login;
